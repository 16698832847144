const coreConstant = {
  // dateFormat: "dd MMM yyyy",
  dateFormat: "dd/MM/yyyy",
  dateTimeFormat: "dd MMM yyyy HH:mm",
  convertDateFormat: "DD MMM YYYY ",
  claimStatusDateFormat: "LL",
  convertDateTimeFormat: "DD MMM YYYY HH:mm",
  backendDateTimeFormat: "DD-MM-yyyy HH:mm",
  convertTimeFormat: "HH:mm",
  backEndDateFormat: "YYYY-MM-DD",
  allowCharacterRegex: {
    fullName: /^[a-zA-Z ]+$/,
    onlyNumber: /^[0-9]+$/,
    decimalNumber: /^[0-9]{0,9}[.]{0,1}[0-9]{0,2}$/,

    registrationNo: /^[a-zA-Z0-9]+$/,
    alphaNumeric: /^[a-zA-Z0-9]+$/,
    alphaNumericSpace: /^[a-zA-Z0-9 ]+$/,
    normalAddress: /^[a-zA-Z0-9 \n.,/.&_()\\-]+$/,
    lossDescription: /^[a-zA-Z0-9,. \n]+$/,
    reasonForDelay: /^[a-zA-Z0-9,. \n]+$/,
    policeStationName: /^[a-zA-Z0,() ]+$/,
    firNumber: /^[a-zA-Z0-9/\\-]+$/,
    workShopName: /^[a-zA-Z0-9,() -]+$/,
    stateCity: /^[a-zA-Z ]+$/,
    driverDl: /^[a-zA-Z0-9,/.&_()\\-]+$/,
    remarks: /^[a-zA-Z0-9,. \n]+$/,
    documentPattern: /^[ a-zA-Z0-9/\\-]+$/,
    commentPattern: /^[a-zA-Z0-9 \n,/.&_()\\-]{1,}(?: [a-zA-Z]+){0,2}$/,
    districtPattern: /^[a-zA-Z() ]+$/,
    pinCodePattern: /^[1-9][0-9]{5}$/,
    restrictSpecialCharacters: /^[a-zA-Z0-9 ]{1,}(?: [a-zA-Z]+){0,2}$/,
    namePattern: /^[a-zA-Z0-9 \n.,/.&_()\\-]{1,}(?: [a-zA-Z]+){0,2}$/,
    newRestrictSpecialCharacters:
      /^[a-zA-Z0-9 ,/.&_()\\-]{1,}(?: [a-zA-Z]+){0,2}$/,
  },
  regex: {
    lowercase: /[a-z]/,
    uppercase: /[A-Z]/,
    number: /[0-9]/,
    specailCharacters: /[!@#$%^&*()_+{}|:"<>?`~\-=[\]\\;',./]/,
    pureCharacter: /^[a-zA-Z]{1,}(?: [a-zA-Z]+){0,2}$/,
    pureNumber: /^[0-9]{1,}(?: [a-zA-Z]+){0,2}$/,
    MobileNumberPattern: /^[6789]\d{9}$/,
    pureSpecialCharacterPattern: /^[a-zA-Z0-9 ]{1,}(?: [a-zA-Z]+){0,2}$/,
    lessSpecialCharacterPattern: /^[a-zA-Z0-9 ,\n./-]{1,}(?: [a-zA-Z]+){0,2}$/,
    districtSpecialCharacterPattern:
      /^[a-zA-Z0-9 ,()\n.-]{1,}(?: [a-zA-Z]+){0,2}$/,
    driverDL: /^[A-Z]{2}(?:\d[- ]*){13}$/,
    firNumberPattern: /^[a-zA-Z0-9/\\-]{1,}(?: [a-zA-Z]+){0,2}$/,
    pinCodePattern: /^[1-9][0-9]{5}$/,
    // RegNoPattern: /^[A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4}$/,

    RegNoPattern: /^[A-Z]{2}[0-9]{1,2}[A-HJ-NP-Z]{0,3}[0-9]{4}$/,
    BhRegNoPattern: /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/,
    verifiedRegNoPattern:
      /^[A-Za-z]{2}[0-9]{1,2}[A-Za-z]{0,3}(?!0000)[0-9]{4}$/,
    alphaNumericPattern: /^[a-zA-Z0-9]+$/,
    panPattern: /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/,
    gstPattern: /^^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    // addressPattern: /^[a-zA-Z0-9,-/]{1,}(?: [a-zA-Z]+){0,2}$/,
    addressPattern: /^[a-zA-Z0-9 ,\n.-]{1,}(?: [a-zA-Z]+){0,2}$/,
    invoiceNumberPattern: /^[a-zA-Z0-9,/.&_()\\-]{1,}(?: [a-zA-Z]+){0,2}$/,
    decimalWithTwoDigit: /^-?\d{1,2}(\.\d{1,2})?$/,
  },
  role: {
    claimManager: "Claim Manager",
    callCenter: "Call Center",
    externalSUrveyor: "External Surveyor",
  },
  pollSeconds: 2 * 1000,
  publicRoutes: ["login", "upload-document", "claim-status"],
  validateUserEndPoint: `validate-session/`,
  pingEndPoint: `ping/`,
  LoginEndPoint: `login-api/`,
  adLoginEndPoint: `ad/login/`,
  searchCityEndPoint: `search-city/`,
  fetchPincodeEndPoint: `pincode/`,
  vehicleMake: `vehicle-make/`,
  autocompletePincode: `get-pincode/`,
  validateAutocompletePincode: `validate-pincode/`,
  claimSearchEndPoint: `search-claim/`,
  passwordResetEndPoint: `reset-password/`,
  validateTokenEndPoint: `validate-token/`,
  checkClaimLock: "<claimID>/check-claim-lock/",
};

export default coreConstant;
